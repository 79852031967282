import config from '@configFile';
import {
  HomeState,
  DealsAction,
  DEALS_UPDATE_COUPON_PAGE_SEO,
  DEALS_GET_DEAL_404,
  DEALS_UPDATE_DEAL_PAGE_SEO,
  DEALS_UPDATE_BLOG_PAGE_SEO,
  DEALS_UPDATE_MOST_POPULAR_DEALS,
  DEALS_UPDATE_DEAL_RAIL,
  DEALS_UPDATE_RECEIPT_DEAL,
  DEALS_GET_DEAL_SUMMARY,
  DEALS_LOADING_DEAL_SUMMARY,
  DEALS_UPDATE_PAGE_DEAL_SLUGS_SEO
} from '../types';

// Export for unit testing
const initialState: HomeState = {
  err: null,
  deals: [],
  primeDayDeals: [],
  withoutKeywordFilterLength: 0,
  displayItemsCount: 0,
  mostPopular: [],
  dealRails: {},
  receiptDeal: null,
  userSuggestionError: null,
  dealSubmissions: [],
  dealPageTitle: 'Hand-Picked Amazon Deals',
  ogPath: config.SITE_URL,
  ogImage: null,
  ogTitle: "Amazon's BEST Deals",
  dealSummary: null,
  dealSummaryError: false,
  dealSummaryASIN: null,
  dealPageSEO: null,
  couponPageSEO: null,
  blogPageSEO: null,
  pageDealSlugsSEO: []
};

export default (state = initialState, action: DealsAction) => {
  switch (action.type) {
    case DEALS_UPDATE_DEAL_RAIL: {
      return {
        ...state,
        dealRails: {
          ...state.dealRails,
          [action.dealRailKey]: action.dealRail
        }
      };
    }
    case DEALS_UPDATE_PAGE_DEAL_SLUGS_SEO:
      return {
        ...state,
        pageDealSlugsSEO: action.pageDealSlugsSEO
      };
    case DEALS_UPDATE_RECEIPT_DEAL:
      const { receiptDeal } = action;
      const dealSummaryASIN = receiptDeal?.ASIN || null;
      const extraParams = {} as {
        ogTitle?: string;
        ogImage?: string;
        ogPath?: string;
        // dealPageTitle?: string;
      };

      if (!dealSummaryASIN) {
        extraParams.ogTitle = initialState.ogTitle;
        extraParams.ogImage = initialState.ogImage;
        extraParams.ogPath = initialState.ogPath;
        // extraParams.dealPageTitle = initialState.dealPageTitle;
      }

      return {
        ...state,
        receiptDeal,
        dealSummaryASIN,
        dealSummary: dealSummaryASIN ? state.dealSummary : null,
        ...extraParams
      };
    case DEALS_LOADING_DEAL_SUMMARY:
      return {
        ...state,
        dealSummaryASIN: action.dealSummaryASIN
      };
    case DEALS_GET_DEAL_404:
      return {
        ...state,
        dealSummaryError: true
      };
    case DEALS_GET_DEAL_SUMMARY:
      const { dealSummary, ogTitle, ogImage, ogPath } = action;
      return {
        ...state,
        dealSummary,
        ogTitle,
        ogImage,
        // dealPageTitle,
        ogPath,
        dealSummaryError: false
      };
    case DEALS_UPDATE_MOST_POPULAR_DEALS:
      const { mostPopular } = action;
      return {
        ...state,
        mostPopular
      };
    case DEALS_UPDATE_DEAL_PAGE_SEO:
      return {
        ...state,
        dealPageSEO: { ...action.dealPageSEO }
      };
    case DEALS_UPDATE_BLOG_PAGE_SEO:
      return {
        ...state,
        blogPageSEO: { ...action.blogPageSEO }
      };
    case DEALS_UPDATE_COUPON_PAGE_SEO:
      return {
        ...state,
        couponPageSEO: { ...action.couponPageSEO }
      };
    default:
      return state;
  }
};
