import axios, { AxiosInstance } from 'axios';
import config from '@configFile';
import {
  GetAvailableDealsParams,
  ThunkDispatch,
  ThunkAction,
  DEALS_UPDATE_DEAL_RAIL,
  DEALS_UPDATE_COUPON_PAGE_SEO,
  DEALS_GET_DEAL_SUMMARY,
  DEALS_LOADING_DEAL_SUMMARY,
  DEALS_GET_DEAL_404,
  DEALS_UPDATE_DEAL_PAGE_SEO,
  DEALS_UPDATE_BLOG_PAGE_SEO,
  DEALS_UPDATE_MOST_POPULAR_DEALS,
  DEALS_UPDATE_PAGE_DEAL_SLUGS_SEO
} from '../types';
import { getPostPrice, getTitleFromPageDeal } from '../utils';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX // Replace with your base URL
});

export const getPageDealSlugs =
  (): ThunkAction => async (dispatch: ThunkDispatch) => {
    try {
      const { data } = await axiosInstance.get(`/api/slugs`);
      dispatch({
        type: DEALS_UPDATE_PAGE_DEAL_SLUGS_SEO,
        pageDealSlugsSEO: [...data]
      });

      // dispatch into the reducer
    } catch (err) {
      console.log(err);
    }
  };

export const getPromoCodeDeal =
  (promoCode: string): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      if (!promoCode) {
        return;
      }

      const { data } = await axiosInstance.get(
        `/api/get-promo-code-seo/${promoCode}`
      );
      dispatch({
        type: DEALS_UPDATE_COUPON_PAGE_SEO,
        couponPageSEO: {
          ...data
        }
      });

      // dispatch into the reducer
    } catch (err) {
      console.log(err);
    }
  };

export const getBlogSEO =
  (slug: string): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      if (!slug) {
        return;
      }
      dispatch({
        type: DEALS_UPDATE_BLOG_PAGE_SEO,
        blogPageSEO: null
      });

      const { data } = await axiosInstance.get(`/api/get-blog-seo/${slug}`);

      // console.log('-------------------> data', data);

      dispatch({
        type: DEALS_UPDATE_BLOG_PAGE_SEO,
        blogPageSEO: {
          ...data
        }
      });
      // console.log('data', data);

      // dispatch into the reducer
    } catch (err) {
      console.log(err);
    }
  };

export const getPageDeal =
  (slug: string): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      if (!slug) {
        return;
      }
      dispatch({
        type: DEALS_UPDATE_DEAL_PAGE_SEO,
        dealPageSEO: null
      });
      const { data } = await axiosInstance.get(`/api/get-page-seo/${slug}`);
      dispatch({
        type: DEALS_UPDATE_DEAL_PAGE_SEO,
        dealPageSEO: {
          ...data?.pageDeal,
          title: getTitleFromPageDeal(data?.pageDeal)
        }
      });

      // dispatch into the reducer
    } catch (err) {
      console.log(err);
    }
  };

export const getDealSummary =
  (ASIN: string): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      if (ASIN === 'apple-touch-icon.png') {
        return;
      }

      dispatch({
        type: DEALS_LOADING_DEAL_SUMMARY,
        dealSummaryASIN: ASIN
      });
      const { data } = await axiosInstance.get(
        `/api/get-deal-summary?ASIN=${ASIN}`
      );

      dispatch({
        type: DEALS_GET_DEAL_SUMMARY,
        dealSummary: data,
        dealSummaryASIN: data?.ASIN,
        ogPath: `${config.SITE_URL}/deal/${data?.ASIN}`,
        ogTitle: data?.title ? `${data?.title} ${getPostPrice(data)}` : '',
        ogImage: data?.image
        // dealPageTitle: data?.title ? `${data?.title} ${getPostPrice(data)}` : '',
      });
    } catch (err) {
      dispatch({
        type: DEALS_GET_DEAL_404
      });
    }
  };

export const getDealRail =
  (
    { limit, sort, filters = [] }: GetAvailableDealsParams,
    key: string
  ): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      const { data } = await axiosInstance.post('/api/get-available-deals', {
        limit,
        sort,
        filters
      });
      dispatch({
        type: DEALS_UPDATE_DEAL_RAIL,
        dealRail: data,
        dealRailKey: key
      });
      // Must have return here to be used for loading server side
      return data; // Successfully resolved the promise with fetched data
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

export const getMostPopularDeals =
  (): ThunkAction => async (dispatch: ThunkDispatch) => {
    try {
      const { data } = await axiosInstance.get('/api/most-popular-deals');

      dispatch({
        type: DEALS_UPDATE_MOST_POPULAR_DEALS,
        mostPopular: data
      });
    } catch (err) {
      console.log(err);
    }
  };
